<style lang="scss" scoped>
.app-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  &.clip:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.2);
  }
  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    animation-duration: var(--interval);
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: ken-burns;
  }

  @keyframes ken-burns {
    100% {
      transform: scale3d(1.25, 1.25, 1.25) translate3d(var(--x), var(--y), 0);
    }
  }
}
</style>

<template>
  <div class="app-slider" :class="clip ? 'clip' : ''">
    <transition-group
      appear
      mode="out-in"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <div
        class="app-slider__image"
        v-for="(image, index) in images"
        :key="index"
        v-show="activeIndex === index"
        :style="`background-image: url('${image}')`"
      ></div>
    </transition-group>
  </div>
</template>

<script>
import { TweenMax } from "gsap/dist/gsap";

export default {
  name: "KenBurnsCarousel",
  props: {
    images: {
      type: Array,
      required: true
    },
    direction: {
      type: String,
      default: "top-left"
    },
    interval: {
      type: [String, Number],
      default: 8000
    },
    clip: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeIndex: 0
  }),
  methods: {
    setInterval(interval) {
      document.documentElement.style.setProperty(
        "--interval",
        `${parseInt(interval) + 3000}ms`
      );
    },
    setDirection(direction) {
      let x;
      let y;
      switch (direction) {
        case "top-left":
          x = "-5%";
          y = "-2.5%";
          break;
        case "top-right":
          x = "5%";
          y = "-2.5%";
          break;
        case "bottom-left":
          x = "-5%";
          y = "2.5%";
          break;
        case "bottom-right":
          x = "5%";
          y = "2.5%";
          break;
      }
      document.documentElement.style.setProperty("--x", x);
      document.documentElement.style.setProperty("--y", y);
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      TweenMax.to(el, { opacity: 1, duration: 3, onComplete: done });
    },
    leave(el, done) {
      TweenMax.to(el, { opacity: 0, duration: 3, onComplete: done });
    }
  },
  mounted() {
    this.setDirection(this.direction);
    this.setInterval(this.interval);
    setInterval(() => {
      if (this.activeIndex === this.images.length - 1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex++;
      }
    }, parseInt(this.interval));
  }
};
</script>
