<style lang="scss" scoped>
.cookie-consent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.7);
  z-index: 10;

  .cookie-consent-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .base-btn {
      width: auto;
    }
    a {
      color: inherit;

      &:hover,
      &:active {
        color: $primary-dark;
      }
    }
  }
}
</style>

<template>
  <div class="cookie-consent" v-if="!CookieConsent.consent">
    <aside class="cookie-consent-bar">
      <p>
        En poursuivant votre navigation, vous acceptez le dépôt de cookies tiers
        destinés à vous proposer des photos, des boutons de partage, des
        remontées de contenus de plateformes sociales.
        <router-link :to="{ name: 'LegalNotice' }">En savoir plus</router-link>
      </p>
      <button class="base-btn primary" @click="consent">J'ai compris</button>
    </aside>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  inject: ["CookieConsent"],
  methods: {
    consent() {
      localStorage.setItem("cookie-consent", JSON.stringify(new Date()));
      this.CookieConsent.consent = localStorage.getItem("cookie-consent");
    }
  },
  mounted() {
    this.CookieConsent.consent = localStorage.getItem("cookie-consent");
  }
};
</script>
